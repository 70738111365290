import {
  useFocusEffect,
  useIsFocused,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { DeviceEventEmitter, TouchableOpacity, View } from "react-native";
import {
  APP_STATE,
  MapsScreenRouteParams,
  ScreenComponent,
} from "../../../types";
import MapView from "./MapView";
import { ChargingStationRequestService } from "../../../miscellaneous/services/ChargingStationRequestService";
import { ErrorHandlerService } from "../../../services/ErrorHandlerService";
import { ReactComponent as PulseEnergyLogo } from "../../../assets/icons/pulse_energy_logo.svg";
import CText from "../../components/CText";
import { IoIosSearch } from "react-icons/io";
import QuickFilters from "../../components/QuickFilters";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  useActivePaymentMethodType,
  useActiveWallet,
  useAppliedFilters,
  useAppliedFiltersChangeVersion,
  useGPSEnabled,
  useLocationPermissionEnabled,
  useMasterUCSMap,
  useNearestUCSMiniObject,
  useShowACChargersOnly,
  useTrackedNearestChargingStation,
  useUsersPhysicalLocation,
} from "../../../hooks";
import { useOngoingChargeTxns } from "../../../hooks/doCharging";
import {
  MiniUCS,
  MixedChargingStation,
} from "../../../central-atoms/types/charging/charging-station";
import ChargingAction, {
  resetNearestUCSMiniObject,
  resetOtherNearbyUCSMiniObjectList,
  updateNearbyUCSMiniObjectsList,
  updateNearestUCSMiniObject,
  updateUCSMasterMap,
} from "../../../actions/chargingActions";
import { isSharedWallet } from "../../../helpers/walletHelpers";
import { ChargeTxnsRequestService } from "../../../services/ChargeTxnsRequestService";
import { updateOngoingChargeTxns } from "../../../actions/doChargingActions";
import { CheckWalletBalance } from "../../../central-atoms/types/app-requests";
import ExternalWalletRequestService from "../../../services/ExternalWalletRequestService";
import { updateActiveWallet } from "../../../actions/walletActions";
import {
  FetchLastUsedCSQueryParams,
  FetchNearbyCSQueryParams,
} from "../../../central-atoms/types/requests/charging/charging-station";
import { FiltersService } from "../../../services/FilterService";
import { useInterval } from "../../../miscellaneous/hooks";
import { SCREEN_NAMES } from "../../../constants";
import { isEmpty } from "lodash";
import { setUsersPhysicalLocation } from "../../../actions/mainActions";
import { COLORS } from "../../../config/colors";
import { horizontalSize, verticalSize } from "../../../functions/responsive";
import { FaUserCircle } from "react-icons/fa";
import { PaymentMethodType } from "../../../central-atoms/payment-methods/types/enum";
import config from "../../../config/config";
import { AvailableAppVariants } from "../../../theme/enums";
import CButton from "../../components/CButton";
import { ScanQRIcon } from "../../../icons";

type Props = ScreenComponent & {};
export default function MapScreen(props: Props) {
  const navigation: any = useNavigation();
  const route = useRoute();
  const { t } = useTranslation();

  // TODO: Fix this
  const placeRouteParam = (route?.params as MapsScreenRouteParams)?.place;
  const [initialMapLocation, setInitialMapLocation] = useState<any | null>(null);
  const [fitToMarkersId, setFitToMarkersId] = useState<string | number | null>(null);

  const [previewModalOpen, setPreviewModalOpen] = useState(true);

  const dispatch = useDispatch();

  const routeParams: any = route.params;
  const returnScreen = routeParams?.returnScreen;

  const isFocused = useIsFocused();

  const showACchargersOnly = useShowACChargersOnly();
  const masterUCSMap = useMasterUCSMap();
  const nearestUCSMiniObject = useNearestUCSMiniObject();
  const appliedFilters = useAppliedFilters();
  const appliedFiltersChangeVersion = useAppliedFiltersChangeVersion();

  // wallet /*
  const activeWallet = useActiveWallet();
  const activePaymentMethodType = useActivePaymentMethodType();
  // wallet */

  const ongoingChargeTxns = useOngoingChargeTxns();
  const ongoingChargeTxnsCount = Object.keys(ongoingChargeTxns).length;

  const nearestUCS = nearestUCSMiniObject?.id
    ? masterUCSMap[nearestUCSMiniObject?.id]
    : null;

  const {
    filterData,
  }: {
    nearestCharger: MixedChargingStation | null;
  } & any = useSelector((state: APP_STATE) => state.chargingStation);

  const {
    setRecentCharger,
    setTrackedNearestChargingStation,
    setLastChargeTransaction,
  } = ChargingAction;

  const [shouldSkipNearbyCSFetch, setShouldSkipNearbyCSFetch] = useState(
    !!returnScreen
  );
  const [shouldFetchNearbyCS, setShouldFetchNearbyCS] = useState(false);
  const [shouldFetchLastUsedCS, setShouldFetchLastUsedCS] = useState(false);
  const [waitForCS, setWaitForCS] = useState(false);

  const usersPhysicalLocation = useUsersPhysicalLocation();
  const gpsEnabled = useGPSEnabled();
  const locPermissionEnabled = useLocationPermissionEnabled();

  const atLocOffset = 50; // m

  const trackedNearestChargingStation = useTrackedNearestChargingStation();

  useEffect(() => {
    if (placeRouteParam?.Place?.place_id) {
      getLatLngForPlace(placeRouteParam?.Place?.place_id);
    }
  }, [placeRouteParam]);

  const getLatLngForPlace = (placeId: string) => {
    ChargingStationRequestService.fetchLatLngForPlaceId(placeId)
      .then((response) => {
        setInitialMapLocation(response.data);
      })
      .catch((e) => {
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error",
          },
        });
      });
  };

  useFocusEffect(() => {
    const changeId = (route?.params as any)?.changeId;
    if (changeId) setFitToMarkersId(changeId);
  });

  // DEV NOTE: wrapped this in useMemo from preventing it from re-rendering on state update

  // Ongoing sessions polling
  const [
    shouldContinueOngoingSessionsLongPolling,
    setShouldContinueOngoingSessionsLongPolling,
  ] = useState<boolean>(false);

  const getOngoingSessions = () => {
    ChargeTxnsRequestService.fetchOngoingSessions()
      .then(({ data }) => {
        dispatch(updateOngoingChargeTxns(data["10"]));
      })
      .catch((e) => {
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            openToast: false,
          },
        });
      });
  };

  const getWalletBalance = () => {
    const data: CheckWalletBalance = {};
    if (isSharedWallet(activeWallet)) {
      data.shared_wallet_id = activeWallet?.shared_wallet_id as string;
    } else {
      data.wallet_id = activeWallet?.id;
    }
    ExternalWalletRequestService.checkWalletBalance(data)
      .then(({ data }) => {
        if (activeWallet?.type === data.wallet_type) {
          dispatch(
            updateActiveWallet({
              ...activeWallet,
              balance: `${data.balance}`,
            })
          );
        }
      })
      .catch((e) => {
        ErrorHandlerService.handleError(e, {
          openToast: false,
        });
      });
  };

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        dispatch(
          setUsersPhysicalLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          })
        );
      },
      (error) => {
        dispatch(
          setUsersPhysicalLocation({
            latitude: 19.07609,
            longitude: 72.877426,
          })
        );
      }
    );
  };

  const getNearbyChargingStations = (pagination?: any) => {
    const { page = 1 } = pagination ?? { page: 1 };

    const queryParams: FetchNearbyCSQueryParams = {
      ...FiltersService.getAppliedFiltersParams(appliedFilters),
      page: page,
    };

    if (
      gpsEnabled &&
      usersPhysicalLocation?.latitude &&
      usersPhysicalLocation?.longitude
    ) {
      queryParams.user_lat = usersPhysicalLocation?.latitude;
      queryParams.user_lng = usersPhysicalLocation?.longitude;
    }

    return ChargingStationRequestService.fetchNearbyChargingStations(
      queryParams
    )
      .then(({ data }) => {
        batch(() => {
          const nearbyUCSs: MixedChargingStation[] = data;
          batch(() => {
            dispatch(updateUCSMasterMap(nearbyUCSs));

            // set nearest
            if (queryParams.page === 1) {
              const foundNearestUCS = nearbyUCSs.find(
                (ucs) => ucs.charging_station.is_nearest
              );
              dispatch(
                updateNearestUCSMiniObject(
                  foundNearestUCS
                    ? {
                      id: `${foundNearestUCS?.central_charging_station.id}`,
                      primary_cs_id: foundNearestUCS?.charging_station.id,
                      created_on: "",
                    }
                    : null
                )
              );
            }

            // set others
            const otherNearbyMiniUSCList: MiniUCS[] = [];
            nearbyUCSs.forEach((ucs) => {
              if (!ucs.charging_station.is_nearest) {
                otherNearbyMiniUSCList.push({
                  id: `${ucs.central_charging_station.id}`,
                  primary_cs_id: ucs.charging_station.id,
                  created_on: "",
                });
              }
            });
            dispatch(
              updateNearbyUCSMiniObjectsList(
                otherNearbyMiniUSCList,
                page === 1 && showACchargersOnly ? "override" : "merge"
              )
            );
          });
        });

        setWaitForCS(false);
        return data;
      })
      .catch((e: any) => {
        setWaitForCS(false);
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error",
          },
        });
      });
  };

  const getLastUsedChargingStation = () => {
    const queryParams: FetchLastUsedCSQueryParams = {};
    if (usersPhysicalLocation?.latitude && usersPhysicalLocation?.longitude) {
      queryParams.user_lat = usersPhysicalLocation?.latitude;
      queryParams.user_lng = usersPhysicalLocation?.longitude;
    }

    ChargingStationRequestService.fetchRecentChargingSession(queryParams)
      .then(({ data }) => {
        dispatch(setRecentCharger(data));

        if (data?.charge_transaction)
          dispatch(setLastChargeTransaction(data?.charge_transaction));
      })
      .catch((e: any) => {
        ErrorHandlerService.handleError(e, {
          openToast: false,
        });
      });
  };

  // long poll for CP connectors
  useInterval(
    () => {
      getOngoingSessions();
    },
    shouldContinueOngoingSessionsLongPolling ? 5000 : null
  );

  useEffect(() => {
    // device event emitter used to
    const subscription = DeviceEventEmitter.addListener(
      "notificationClickHandle",
      (data: any) => { }
    );
    return () => {
      subscription.remove();
    };
  }, []);

  useEffect(() => {
    if (shouldFetchNearbyCS) getNearbyChargingStations({ page: 1 });

    // get last used charging station
    if (shouldFetchLastUsedCS) getLastUsedChargingStation();
    // }
  }, [
    locPermissionEnabled,
    gpsEnabled,
    usersPhysicalLocation?.latitude,
    usersPhysicalLocation?.longitude,
  ]);

  useEffect(() => {
    if (routeParams?.type === "filter" && routeParams?.changeId) {
      setWaitForCS(true);
      getNearbyChargingStations({ page: 1 }).finally(() => {
        setWaitForCS(false);
      });
    }
  }, [routeParams?.type, routeParams?.changeId]);

  useEffect(() => {
    if (isFocused && !shouldSkipNearbyCSFetch) {
      batch(() => {
        dispatch(resetNearestUCSMiniObject());
        dispatch(resetOtherNearbyUCSMiniObjectList());
      });
      setWaitForCS(true);
      getNearbyChargingStations({ page: 1 }).finally(() => {
        setWaitForCS(false);
      });
    }
  }, [appliedFiltersChangeVersion]);

  useEffect(() => {
    if (!isEmpty(nearestUCS) && nearestUCS) {
      if (
        // CS was not tracked yet
        !trackedNearestChargingStation ||
        // not the same CS
        (nearestUCS.central_charging_station.id !==
          trackedNearestChargingStation.central_charging_station.id &&
          // and the user is at the CS
          nearestUCS.charging_station.distance <= atLocOffset)
      ) {
        dispatch(setTrackedNearestChargingStation(nearestUCS));
      }
    }
  }, [nearestUCS]);

  useEffect(() => {
    getCurrentLocation();
    if (activePaymentMethodType === PaymentMethodType.Wallet)
      getWalletBalance();
  }, []);

  useEffect(() => {
    if (!isEmpty(ongoingChargeTxns)) {
      setShouldContinueOngoingSessionsLongPolling(true);
    } else {
      setShouldContinueOngoingSessionsLongPolling(false);
    }
  }, [ongoingChargeTxnsCount]);

  useFocusEffect(
    useCallback(() => {
      getOngoingSessions();

      // added here for resetting the color when coming back from Charging Process screen

      setShouldFetchNearbyCS(true);
      setShouldFetchLastUsedCS(true);
      setShouldSkipNearbyCSFetch(false);

      return () => {
        setShouldFetchNearbyCS(false);
        setShouldFetchLastUsedCS(false);
      };
    }, [])
  );

  return (
    <View
      style={{
        backgroundColor: COLORS.whiteColor,
        height: verticalSize(100),
        // height: '100%',
        position: "relative",
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* Top bar */}
      <View
        style={{
          // height: verticalSize(13),
          // position: "sticky" as any,
          // top: 0,
          // left: 0,
          // right: 0,
          // zIndex: 500,
          flex: 0.1,
          minHeight: 'auto',
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            paddingHorizontal: 20,
            alignItems: "center",
            paddingVertical: 10,
          }}
        >
          <div>
            <img src={config.FULL_LOGO_URL} alt={config.APP_NAME} style={{ height: 16 }} />
          </div>

          <View>
            <View style={{ flexDirection: "row" }}>
              <TouchableOpacity
                style={{ flexDirection: "row", alignItems: "center" }}
                onPress={() => navigation.navigate(SCREEN_NAMES.SearchScreen)}
              >
                <CText
                  size={10}
                  style={{ color: COLORS.primaryGrey, fontWeight: "500" }}
                  semiBold
                >
                  {t("search")}
                </CText>
                <IoIosSearch
                  color={COLORS.primaryGrey}
                  style={{ marginLeft: 8 }}
                  size={17}
                />
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: horizontalSize(4),
                }}
                onPress={() => navigation.navigate(SCREEN_NAMES.ProfileScreen)}
              >
                <FaUserCircle
                  color={COLORS.primaryGrey}
                  style={{ marginLeft: 8 }}
                  size={17}
                />
              </TouchableOpacity>
            </View>
          </View>
        </View>

        {/* horizontal line */}
        <View
          style={{
            height: 1,
            backgroundColor: COLORS.primaryLight,
            width: "90%",
            alignSelf: "center",
          }}
        />

        <View
          style={{
            // DEV NOTE: height & flexDirection are required
            height: verticalSize(8),
            flexDirection: "row",
          }}
        >
          <View style={{ flex: 1 }}>
            <QuickFilters context="maps_screen" disableItems={waitForCS} />
          </View>
        </View>
      </View>

      {/* map view */}
      <View id="map-view" style={{
        // height: verticalSize(87), 
        // overflow: "auto" as any 
        flex: 1,
      }}>
        <MapView
          fitToMarkersId={fitToMarkersId}
          initialMapLocation={initialMapLocation}
          onPreviewModalOpen={(open) => {
            setPreviewModalOpen(open);
          }}
        />
      </View>

      {/* bottom bar */}
      {
        !previewModalOpen &&
        <View id="bottom-bar" style={{
          padding: 16,
          // flex: 0.1,
          minHeight: 'auto',
          position: 'absolute',
          bottom: 0,
          width: '100%',
          backgroundColor: 'white',
        }}>
          <CButton
            textStyle={{ color: COLORS.whiteColor }}
            title={`${t("scanQRCode")}`}
            icon={() => {
              return (
                <ScanQRIcon
                  fill={COLORS.whiteColor}
                  style={{
                    height: 24,
                    width: 24,
                    marginRight: 8,
                  }}
                />
              );
            }}
            onPress={() => {
              navigation.navigate(
                SCREEN_NAMES.ChargePointQRScannerScreen as never
              );
            }}
          />
        </View>
      }
    </View>
  );
}
