// import { getAppVariantFromDOM } from '../helpers';
import { AvailableAppVariants } from '../theme/enums';

export function getAppVariantFromDOM(): string | null | undefined {
  const root = document.querySelector('#root');
  const variant = root?.getAttribute('data-app-variant');
  console.log('getAppVariantFromDOM', variant);
  return variant;
};

/**
 * @todo move this somewhere else
 */
const appVariantsMap = {
  [AvailableAppVariants.PulseEnergyLite]: {WHITE_LABEL_ID: AvailableAppVariants.PulseEnergyLite},
  [AvailableAppVariants.GudzLite]: {WHITE_LABEL_ID: AvailableAppVariants.GudzLite},
  [AvailableAppVariants.EVFinLite]: {WHITE_LABEL_ID: AvailableAppVariants.EVFinLite},
  [AvailableAppVariants.AllianzLite]: {WHITE_LABEL_ID: AvailableAppVariants.AllianzLite},
  [AvailableAppVariants.EulerMotorsLite]: {WHITE_LABEL_ID: AvailableAppVariants.EulerMotorsLite},
};

function getConfigFromHostname() {
  /**
   * @WHITE_LABEL_CHANGES
   */

  console.log('window.location', window.location);
  
  const hostname = window.location.hostname;

  if (hostname.includes(AvailableAppVariants.PulseEnergyLite)) {
    return appVariantsMap[AvailableAppVariants.PulseEnergyLite];
  }
  else if (hostname.includes(AvailableAppVariants.GudzLite)) {
    return appVariantsMap[AvailableAppVariants.GudzLite];
  }
  else if (hostname.includes(AvailableAppVariants.EVFinLite)) {
    return appVariantsMap[AvailableAppVariants.EVFinLite];
  }
  else if (hostname.includes(AvailableAppVariants.AllianzLite)) {
    return appVariantsMap[AvailableAppVariants.AllianzLite];
  }
  else if (hostname.includes(AvailableAppVariants.EulerMotorsLite)) {
    return appVariantsMap[AvailableAppVariants.EulerMotorsLite];
  }
  
  console.log('Falling back to default');
  return appVariantsMap[AvailableAppVariants.PulseEnergyLite];
}

const domAV = getAppVariantFromDOM();

const avConfig = domAV ? appVariantsMap[domAV] : getConfigFromHostname();

// eslint-disable-next-line import/first
// import jsonConfig from `./../app-variant-config/${avConfig.WHITE_LABEL_ID}/config.json`;
const jsonConfig = require(`./../app-variant-config/${avConfig?.WHITE_LABEL_ID || AvailableAppVariants.PulseEnergyLite}/config.json`);

const config = (jsonConfig as any).config;


config.WHITE_LABEL_ID = avConfig.WHITE_LABEL_ID;
config.APP_ENV = process.env.REACT_APP_ENV || config.APP_ENV;

console.log('new config', config);

export default config;
