// this has to be the same as WHITE_LABEL_ID specified in env.*.yaml


/**
 * @WHITE_LABEL_CHANGES
 */

export enum AvailableAppVariants {
    /**
     * @deprecated
     */
    instacharge = 'instacharge',
    /**
     * @deprecated
     */
    thunderplus = 'thunderplus',
    /**
     * @deprecated
     */
    zerofootprint = 'zerofootprint',


    PulseEnergyLite = 'pulseenergylite',
    GudzLite = 'gudzlite',
    EVFinLite = 'evfinlite',
    EulerMotorsLite = 'eulermotorslite',
    AllianzLite = 'allianzlite',
}

/**
 * @deprecated
 */
export const APP_VARIANT_HEADERS_MAP = {
    // lite apps
    [AvailableAppVariants.PulseEnergyLite]: 'pulseenergy_lite_hkq46v4dte2ej10',
    [AvailableAppVariants.GudzLite]: 'gudz_lite_z8jbcg8cjs78ksk',
    [AvailableAppVariants.EVFinLite]: 'evfin_lite_dvc7c7gt87cdo10',
    [AvailableAppVariants.EulerMotorsLite]: 'eulermotors_lite_b2ts4s4s25x5ns5',
    [AvailableAppVariants.AllianzLite]: 'allianz_lite_s8zj1k55k6bq3i2',
};
