import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { useNavigation } from "@react-navigation/native";
import GoogleMapReact from "google-map-react";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaLocationCrosshairs } from "react-icons/fa6";
import {
  FlatList,
  Modal,
  ScrollView,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
  useWindowDimensions,
} from "react-native";
import { batch, useDispatch } from "react-redux";
import {
  resetMapsNearbyUCS,
  updateMapsNearbyUCS,
  updateUCSMasterMap,
} from "../../../actions/chargingActions";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import { AccessibilityType } from "../../../central-atoms/accessibility/enums";
import { MediaType } from "../../../central-atoms/enums/generic/file-upload";
import { Coords } from "../../../central-atoms/types";
import { ChargePoint } from "../../../central-atoms/types/charging/charge-points";
import {
  MiniUCS,
  MixedChargingStation,
} from "../../../central-atoms/types/charging/charging-station";
import { FetchChargingStationsNearbyReqPayload } from "../../../central-atoms/types/requests/charging/charging-station";
import { COLORS } from "../../../config/colors";
import { Images } from "../../../config/image";
import { GOOGLE_MAPS_KEY } from "../../../config/setting";
import {
  GOOGLE_MAPS_STYLE,
  SCREEN_NAMES
} from "../../../constants";
import { verticalSize } from "../../../functions/responsive";
import {
  useAppliedFilters,
  useAppliedFiltersChangeVersion,
  useMapCoords,
  useMapsNearbyUCS,
  useMasterUCSMap,
  useUsersPhysicalLocation,
} from "../../../hooks";
import { useOngoingChargeTxns } from "../../../hooks/doCharging";
import { StyleHelpers } from "../../../miscellaneous/constants/style-helpers";
import { ChargingStationRequestService } from "../../../miscellaneous/services/ChargingStationRequestService";
import ChargingStationService from "../../../services/ChargingStationService";
import { ErrorHandlerService } from "../../../services/ErrorHandlerService";
import { FiltersService } from "../../../services/FilterService";
import { THEME_COLORS } from "../../../theme/constants/themeColors";
import { ChargingStationDetailScreenRouteParams } from "../../../types";
import CText from "../../components/CText";
import ChargingStationCard from "../../components/ChargingStationCard";
import OngoingChargeTxnCard from "../../components/OngoingChargeTxnCard";

export function getMapMarker(ucs: MixedChargingStation, activeUCSId?: string) {
  const isActive = activeUCSId === ucs.central_charging_station.id;
  const controllableCPs: ChargePoint[] =
    ChargingStationService.getControllableCPs(
      ucs?.central_charging_station?.charge_points
    ) || 0;
  const hasUncontrollableCPs =
    (ucs?.central_charging_station?.charge_points?.length || 0) -
    controllableCPs.length >
    0;
  const hasPrivateCPs = ucs?.central_charging_station.accessibility.includes(
    AccessibilityType.Private
  );

  // default
  let markerImg = isActive ? Images.marker_big : Images.marker;
  if (hasPrivateCPs)
    markerImg = isActive ? Images.marker_private_big : Images.marker_private;
  else if (hasUncontrollableCPs)
    markerImg = isActive ? Images.marker_cloud_big : Images.marker_cloud;

  let brandMarker: any,
    brandMarkerBig: any,
    brandMarkerCloud: any,
    brandMarkerCloudBig: any,
    brandMarkerPrivate: any,
    brandMarkerPrivateBig: any;

  // assign variables
  ucs?.central_charging_station?.brand?.medias?.forEach((mediaItem: any) => {
    // for active
    if (mediaItem.type === MediaType.LocationPinBig)
      brandMarkerBig = mediaItem.full_url;

    if (mediaItem.type === MediaType.LocationPinCloudBig)
      brandMarkerCloudBig = mediaItem.full_url;

    if (mediaItem.type === MediaType.LocationPinPrivateBig)
      brandMarkerPrivateBig = mediaItem.full_url;

    // for inactive
    if (mediaItem.type === MediaType.LocationPin)
      brandMarker = mediaItem.full_url;

    if (mediaItem.type === MediaType.LocationPinCloud)
      brandMarkerCloud = mediaItem.full_url;

    if (mediaItem.type === MediaType.LocationPinPrivate)
      brandMarkerPrivate = mediaItem.full_url;
  });

  // final decision
  if (isActive) {
    // since, its not active, Only Big images here
    if (hasPrivateCPs && brandMarkerPrivateBig)
      markerImg = brandMarkerPrivateBig;
    else if (hasUncontrollableCPs && brandMarkerCloudBig)
      markerImg = brandMarkerCloudBig;
    else if (brandMarkerBig) markerImg = brandMarkerBig;
  } else {
    // since, its not active, no Big images here
    if (hasPrivateCPs && brandMarkerPrivate) markerImg = brandMarkerPrivate;
    else if (hasUncontrollableCPs && brandMarkerCloud)
      markerImg = brandMarkerCloud;
    else if (brandMarker) markerImg = brandMarker;
  }

  return markerImg;
}

type Props = {
  shouldFitToMarkers?: boolean;
  fitToMarkersId?: string | number | null;
  initialMapLocation?: Coords | null;
  onPreviewModalOpen?: (open: boolean) => void
};
export default function MapView(props: Props) {
  const {
    fitToMarkersId = null,
    initialMapLocation = null,
    onPreviewModalOpen = () => { }
  } = props;

  const { t } = useTranslation();
  const windowDim = useWindowDimensions();
  const windowWidth = windowDim.width;
  const csCardWidth = windowWidth;

  const dispatch = useDispatch();
  const navigation: any = useNavigation();

  const usersPhysicalLocation = useUsersPhysicalLocation();
  const mapCoords = useMapCoords();

  const appliedFilters = useAppliedFilters();

  const appliedFiltersChangeVersion = useAppliedFiltersChangeVersion();

  const [mapRadius, setMapRadius] = useState(mapCoords.radius ?? 40);
  const [mapLat, setMapLat] = useState(usersPhysicalLocation?.latitude);
  const [mapLng, setMapLng] = useState(usersPhysicalLocation?.longitude);
  const [markerClusterObj, setMarkerClustersObj] = useState<any>(null);

  const masterUCSMap = useMasterUCSMap();
  const mapsNearbyUCS = useMapsNearbyUCS();
  const mapsNearbyUCSList = useMemo(() => {
    return Object.values(mapsNearbyUCS);
  }, [mapsNearbyUCS]);
  const primaryCSIds = useMemo(() => {
    return mapsNearbyUCSList.map((miniUCS) => miniUCS.primary_cs_id);
  }, [mapsNearbyUCSList]);

  const [chargingStations, setChargingStations] = useState(mapsNearbyUCSList);

  // modals /*
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [activeMiniUCS, setActiveMiniUCS] = useState<MiniUCS | null>(null);
  const [activeMiniUCSIndex, setActiveMiniUCSIndex] = useState<number | null>(
    null
  );

  const flatListRef = useRef<FlatList>(null);
  const [map, setMap] = useState<any>(null);
  const [maps, setMaps] = useState<any>(null);
  const ongoingChargeTxns = useOngoingChargeTxns();
  const ongoingChargeTxnsCount = Object.keys(ongoingChargeTxns).length;

  const viewConfigRef = useRef({
    viewAreaCoveragePercentThreshold: 50,
  });

  const onViewRef = useRef((viewableItems: any) => {
    const miniUCS: MiniUCS = viewableItems?.viewableItems?.[0]?.item;

    if (!miniUCS) {
      return;
    }

    const ucs = masterUCSMapRef.current?.[miniUCS?.id];

    if (googleMapRef.current) {
      navigateToCoords(
        googleMapRef.current,
        ucs.charging_station.latitude,
        ucs.charging_station.longitude
      );
    }

    setActiveMiniUCS(miniUCS);
    setActiveMiniUCSIndex(viewableItems?.viewableItems?.[0]?.index);
  });

  const navigateToCoords = (map, lat, lng) => {
    if (map) {
      // Replace with your desired coordinates
      const desiredCenter = {
        lat: lat,
        lng: lng,
      };

      // Set the center of the map to the desired location
      map.setCenter(desiredCenter);

      // You can also adjust the zoom level if needed
      map.setZoom(17);
    }
  };

  const masterUCSMapRef = useRef<{ [key: string]: MixedChargingStation }>({});
  const googleMapRef = useRef<any>({});

  const renderBottomMapCards = ({ item }: { item: MiniUCS; index: number }) => {
    const ucs = masterUCSMap?.[item.id];
    if (!ucs) return null;

    return (
      <View
        style={{
          width: csCardWidth,
          paddingHorizontal: 16,
          justifyContent: "flex-end",
        }}
      >
        <View
          style={{
            marginTop: 10,
          }}
        >
          <ChargingStationCard
            source={"map_screen"}
            mixedChargingStation={ucs}
            showPrimaryActionButton={false}
            showConnectors={false}
            showPower={false}
            showPrice={false}
            coords={{
              latitude: ucs?.charging_station?.latitude,
              longitude: ucs?.charging_station?.longitude,
            }}
            onViewChargerPress={() => {
              setPreviewModalOpen(false);
              navigation.navigate(
                SCREEN_NAMES.ChargingStationDetailScreen as never,
                {
                  chargerId: ucs.charging_station.id,
                  chargerIdType: "charging-station",
                  isDeepLinked: false,
                } as ChargingStationDetailScreenRouteParams as never
              );
            }}
            onStartChargingPress={() => {
              setPreviewModalOpen(false);
              navigation.navigate(
                SCREEN_NAMES.ChargePointQRScannerScreen as any as never,
                { chargeData: ucs } as any as never
              );
            }}
          />
          <TouchableOpacity
            style={{
              position: "absolute",
              height: 26,
              width: 26,
              backgroundColor: COLORS.whiteColor,
              borderRadius: 100,
              borderWidth: 1,
              borderColor: COLORS.primaryLight,
              top: -10,
              right: -10,
              zIndex: 100,
              ...StyleHelpers.totalCenter,
            }}
            onPress={() => {
              setActiveMiniUCS(null);
              setPreviewModalOpen(false);
              if (map && maps) {
                reloadMap(map, maps, true);
              }
            }}
          >
            <CloseIcon fill={COLORS.blackColor} height={16} width={18} />
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  useEffect(() => {
    masterUCSMapRef.current = masterUCSMap;
  }, [masterUCSMap]);

  useEffect(() => {
    googleMapRef.current = map;
  }, [map]);

  const getNearbyCS = () => {
    const data: FetchChargingStationsNearbyReqPayload = {
      user_lat: usersPhysicalLocation?.latitude,
      user_lng: usersPhysicalLocation?.longitude,
      lat: mapLat,
      lng: mapLng,
      radius: mapRadius,
      ...FiltersService.getAppliedFiltersParams(appliedFilters),
    };

    if (primaryCSIds.length) {
      data.exclusion_list =
        ChargingStationService.getExclusionList(primaryCSIds);
    }

    ChargingStationRequestService.fetchChargingStationsNearby(data)
      .then((response: { data: MixedChargingStation[] }) => {
        batch(() => {
          dispatch(updateUCSMasterMap(response.data));
          dispatch(updateMapsNearbyUCS(response.data));
        });
      })
      .catch((e: any) => {
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error",
          },
        });
      });
  };

  const fitToMarkers = (miniUCSList: MiniUCS[]) => {
    const bounds = new window.google.maps.LatLngBounds();
    miniUCSList.map((item) => {
      bounds.extend(
        new window.google.maps.LatLng(
          masterUCSMap[item.id]?.charging_station?.latitude,
          masterUCSMap[item.id]?.charging_station?.longitude
        )
      );
    });

    googleMapRef.current.fitBounds(bounds, {
      edgePadding: {
        top: 50,
        right: 20,
        bottom: 20,
        left: 20,
      },
      animated: false,
    });
  };

  useEffect(() => {
    dispatch(resetMapsNearbyUCS());
  }, [appliedFiltersChangeVersion]);

  useEffect(() => {
    if (fitToMarkersId) {
      fitToMarkers(mapsNearbyUCSList);
    }
  }, [fitToMarkersId]);

  useEffect(() => {
    mapLat && mapLng && getNearbyCS();
  }, [mapLat, mapLng]);

  useEffect(() => {
    setChargingStations(mapsNearbyUCSList);
    if (map && maps) {
      reloadMap(map, maps, true);
    }
  }, [mapsNearbyUCSList]);

  useEffect(() => {
    if (previewModalOpen && activeMiniUCSIndex) {
      setTimeout(() => {
        flatListRef.current?.scrollToIndex({
          animated: true,
          index: activeMiniUCSIndex,
        });
      }, 10);
    }
  }, [activeMiniUCSIndex, previewModalOpen]);

  useEffect(() => {
    onPreviewModalOpen(previewModalOpen);
  }, [previewModalOpen]);

  useEffect(() => {
    if (initialMapLocation?.latitude && initialMapLocation?.longitude) {
      if (map) {
        // Replace with your desired coordinates
        const desiredCenter = {
          lat: initialMapLocation.latitude,
          lng: initialMapLocation.longitude,
        };

        // Set the center of the map to the desired location
        map.setCenter(desiredCenter);

        // You can also adjust the zoom level if needed
        map.setZoom(17);
      }
    }
  }, [initialMapLocation?.latitude, initialMapLocation?.longitude]);
  const defaultProps = {
    center: {
      lat: usersPhysicalLocation.latitude,
      lng: usersPhysicalLocation.longitude,
    },
    zoom: 17,
  };

  const createMapOptions = () => {
    return {
      draggable: true,
      zoomControl: false, // Hide the default zoom control
      mapTypeControl: false, // Hide the default map type control,
      gestureHandling: "greedy",
      fullscreenControl: false,
      disableDefaultUI: true,
      styles: GOOGLE_MAPS_STYLE,
    };
  };

  const createMarker = (
    map,
    maps,
    position,
    iconUrl,
    onClick,
    isCurrentLocationMarker = false
  ) => {
    const marker = new maps.Marker({
      position,
      map,
      icon: {
        url: iconUrl,
        scaledSize: isCurrentLocationMarker
          ? new maps.Size(30, 30)
          : new maps.Size(40, 40), // Adjust the size as needed
      },
    });

    // Add click event listener
    marker.addListener("click", () => {
      onClick(marker);
      if (map && !isCurrentLocationMarker) {
        map.setCenter(position);

        // You can also adjust the zoom level if needed
        map.setZoom(17);
      }
    });

    return marker;
  };

  const reloadMap = async (map, maps, renderCurrentLocation) => {
    // Add your markers
    let markers: any = [];

    chargingStations?.map(async (item: MiniUCS, index: number) => {
      const ucs = masterUCSMap?.[item.id];
      if (!ucs) {
        return null;
      }

      const markerImg = await getMapMarker(ucs, activeMiniUCS?.id);
      markers.push(
        createMarker(
          map,
          maps,
          {
            lat: ucs?.charging_station?.latitude,
            lng: ucs?.charging_station?.longitude,
          },
          markerImg,
          (marker) => handleMarkerClick(marker, ucs, item, index)
        )
      );
    });

    if (usersPhysicalLocation && renderCurrentLocation) {
      markers.push(
        createMarker(
          map,
          maps,
          {
            lat: usersPhysicalLocation.latitude,
            lng: usersPhysicalLocation.longitude,
          },
          Images.user_location,
          (marker) => handleMarkerClick(marker, null, {}, 0),
          true
        )
      );
    }
    await markerClusterObj.removeMarkers(markers);
    await markerClusterObj.clearMarkers();
    await markerClusterObj.render();
    await markerClusterObj.addMarkers(markers);
  };

  const handleApiLoaded = async (map, maps, renderCurrentLocation) => {
    setMap(map);
    setMaps(maps);
    // Add your markers
    let markers: any = [];

    chargingStations?.map((item: MiniUCS, index: number) => {
      const ucs = masterUCSMap?.[item.id];
      if (!ucs) {
        return null;
      }

      const markerImg = getMapMarker(ucs, activeMiniUCS?.id);
      markers.push(
        createMarker(
          map,
          maps,
          {
            lat: ucs?.charging_station?.latitude,
            lng: ucs?.charging_station?.longitude,
          },
          markerImg,
          (marker) => handleMarkerClick(marker, ucs, item, index)
        )
      );
    });

    if (usersPhysicalLocation && renderCurrentLocation) {
      markers.push(
        createMarker(
          map,
          maps,
          {
            lat: usersPhysicalLocation.latitude,
            lng: usersPhysicalLocation.longitude,
          },
          Images.user_location,
          (marker) => handleMarkerClick(marker, null, {}, 0),
          true
        )
      );
    }

    const renderer = {
      render: ({ count, position }, stats) => {
        const color = COLORS.primaryColor;
        // const color =
        //   count > Math.max(10, stats.clusters.markers.mean)
        //     ? COLORS.primaryColor
        //     : COLORS.primaryDark;
        // create svg url with fill color
        const svg = window.btoa(`
    <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
      <circle cx="120" cy="120" opacity=".6" r="70" />
      <circle cx="120" cy="120" opacity=".3" r="90" />
      <circle cx="120" cy="120" opacity=".2" r="110" />
      <circle cx="120" cy="120" opacity=".1" r="130" />
    </svg>`);

        // create marker using svg icon
        return new google.maps.Marker({
          position,
          icon: {
            url: `data:image/svg+xml;base64,${svg}`,
            scaledSize: new google.maps.Size(45, 45),
          },
          label: {
            text: String(count),
            color: "rgba(255,255,255,0.9)",
            fontSize: "12px",
          },
          // adjust zIndex to be above other markers
          zIndex: 1000 + count,
        });
      },
    };

    // Create a marker clusterer
    const markerCluster = new MarkerClusterer({ map, markers, renderer });
    setMarkerClustersObj(markerCluster);
  };

  const handleMarkerClick = (marker, ucs, item, index) => {
    // Handle the marker click event
    // If the marker is associated with a charging station (ucs), you can access its data
    if (ucs) {
      setPreviewModalOpen(true);
      setActiveMiniUCS(item);
      setActiveMiniUCSIndex(index);
      // You can open a modal, navigate to a detail screen, etc.
    }
  };

  const recenterMap = () => {
    if (map) {
      // Replace with your desired coordinates
      const desiredCenter = {
        lat: usersPhysicalLocation.latitude,
        lng: usersPhysicalLocation.longitude,
      };

      // Set the center of the map to the desired location
      map.setCenter(desiredCenter);

      // You can also adjust the zoom level if needed
      map.setZoom(17);
    }
  };

  const handleOutsideClick = () => {
    if (previewModalOpen) {
      setPreviewModalOpen(false);
      setActiveMiniUCS(null);
      if (map && maps) {
        reloadMap(map, maps, true);
      }
    }
  };

  return (
    <div style={{ flex: 1, }}>
      <div style={{ width: "100%", height: "100%", zIndex: 10, }}>
        <GoogleMapReact
          // TODO: change this
          bootstrapURLKeys={{ key: GOOGLE_MAPS_KEY }}
          center={defaultProps.center}
          zoom={defaultProps.zoom}
          onChange={({ center, zoom, bounds, marginBounds }) => {
            setMapLat(center.lat);
            setMapLng(center.lng);
            // if (map && maps) {
            //   reloadMap(map, maps, true);
            // }
          }}
          options={{
            draggable: true,
            zoomControl: false, // Hide the default zoom control
            mapTypeControl: false, // Hide the default map type control,
            gestureHandling: "greedy",
            fullscreenControl: false,
            disableDefaultUI: true,
            clickableIcons: false,
            styles: GOOGLE_MAPS_STYLE,
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps, true)}
        />

        {/* recenter map */}
        <TouchableOpacity
          onPress={() => recenterMap()}
          style={{
            position: "absolute",
            top: 20,
            right: 20,
            borderColor: COLORS.primaryLight,
            borderWidth: 1,
            padding: 10,
            borderRadius: 20,
            backgroundColor: COLORS.whiteColor,
            ...StyleHelpers.shadow,
          }}
        >
          <FaLocationCrosshairs size={18} color={COLORS.primaryColor} />
        </TouchableOpacity>

        <Modal
          style={{
            flex: 1,
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
          visible={previewModalOpen}
          transparent={true}
        >
          <TouchableWithoutFeedback onPress={handleOutsideClick}>
            <View style={{ flex: 1 }}>
              <FlatList
                ref={flatListRef}
                data={mapsNearbyUCSList}
                renderItem={renderBottomMapCards}
                onScrollToIndexFailed={(info) => { }}
                contentContainerStyle={{
                  alignItems: "flex-end",
                  paddingBottom: 20,
                }}
                getItemLayout={(data, index) => ({
                  length: windowWidth,
                  index,
                  offset: windowWidth * index,
                })}
                keyExtractor={(item, index) => item.id}
                horizontal
                initialScrollIndex={activeMiniUCSIndex}
                pagingEnabled
                showsHorizontalScrollIndicator={false}
                onViewableItemsChanged={onViewRef.current}
                viewabilityConfig={viewConfigRef.current}
              />
            </View>
          </TouchableWithoutFeedback>
        </Modal>
      </div>

      {/* ongoing sessions */}
      {
        (!previewModalOpen && !isEmpty(ongoingChargeTxns)) &&
        <View
          style={{
            backgroundColor: COLORS.whiteColor,
            justifyContent: "center",
            borderColor: COLORS.whiteColor,
            paddingHorizontal: 12,
            position: "sticky" as any,
            bottom: 0,
            left: 0,
            right: 0,
            display: "block" as any,
            width: "100%",
            zIndex: 100,
            paddingVertical: verticalSize(2),
          }}
        >
          <View>
            <View
              style={{
                ...StyleHelpers.verticallyCenteredRow,
              }}
            >
              <CText
                semiBold
                size={12}
                style={{ fontWeight: "600" }}
                color={THEME_COLORS.textColor}
              >
                {t("OngoingSessions")}
              </CText>
              <View
                style={{
                  backgroundColor: COLORS.primaryColor,
                  borderRadius: 6,
                  paddingHorizontal: 8,
                  marginLeft: 6,
                }}
              >
                <CText semiBold color={COLORS.whiteColor} size={9}>
                  {ongoingChargeTxnsCount}
                </CText>
              </View>
            </View>
            <ScrollView
              style={{
                marginTop: 8,
                marginBottom: 20,
                marginLeft: -16,
                marginRight: -16,
              }}
              horizontal={true}
              showsHorizontalScrollIndicator={false}
            >
              <View
                style={{
                  ...StyleHelpers.verticallyCenteredRow,
                  marginLeft: 16,
                  marginRight: 16,
                }}
              >
                {Object.values(ongoingChargeTxns).map((ct, index) => {
                  return (
                    <OngoingChargeTxnCard
                      key={ct.id}
                      chargeTxn={ct}
                      style={{
                        width: windowWidth - (ongoingChargeTxnsCount === 1 ? 32 : 54),
                        marginLeft: index === 0 ? 0 : 16,
                      }}
                    />
                  );
                })}
              </View>
            </ScrollView>
          </View>
        </View>
      }
    </div>
  );
}
