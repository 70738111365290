export enum PaymentTxnRefundStatus {
    Initiated = 'Initiated',
    Pending = 'Pending',
    Failed = 'Failed',
    Completed = 'Completed',
}

export enum PaymentSDK {
    Paytm = 'paytm',
    Razorpay = 'razorpay',
    BillDesk = 'billdesk',
    Khalti = 'khalti'
}
